import React from "react";
import { graphql } from "gatsby";
import Header from "../components/Header"
import Footer from "../components/Footer";
import Metadata from "../components/Metadata";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <div className="container">

    <Metadata title={frontmatter.title} />

    <Header />
        <article>
            <h3>{frontmatter.title}</h3>
            
            <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
            />

        <div className="date">{frontmatter.date}</div>
        </article>  

      <Footer />
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`